<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
            <el-button @click="addOrUpdateHandle" type="primary" style="border-radius: 4px">上传</el-button>
<!--            <el-button @click="handleClick('down')" type="primary">下载</el-button>-->
<!--            <el-button @click="handleClick('del')" type="primary">删除</el-button>-->
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getMaterialForTrainingList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
            >
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="120"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="详情" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_detail"
                                    @click="showPdf(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="下载" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_download"
                                    @click="handleClick('down',scope.row.ossId)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="handleClick('del',scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!-- 弹窗, 新增 / 修改 -->
            <el-dialog
                v-bind="readDialog"
                width="760px"
                :title="readDialog.title"
                v-if="readDialog.visible"
                :visible.sync="readDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-or-update ref="addOrUpdate" :id="readDialog.currentId" :type="readDialog.type"></add-or-update>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="readDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="submitHandbookHandle" size="mini">确定</el-button>
                </span>
            </el-dialog>
        </div>

  </div>
</template>

<script>
import {previewPdf} from '@/utils';
import AddOrUpdate from './create';
import * as dayjs from 'dayjs';
export default {
    data() {
        return {
            baseServer: this.axios.defaults.baseURL + '/files/',
            orgs: [],
            selectedRecords: [],
            readDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            }
        };
    },
    components: {
        AddOrUpdate
    },
    computed: {
        columns() {
            return [
                /*{
                    type: 'selection',
                },*/
                {
                    prop: 'name',
                    label: '培训名称',
                },
                {
                    prop: 'createOrg',
                    label: '上传部门',
                },
                {
                    prop: 'releaseOrg',
                    label: '发布部门',
                },
                {
                    prop: 'releaseTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD');
                            return time;
                        }
                        return '';
                    },
                    label: '发布时间',
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'orgId',
                        itemType: 'select',
                        clearable: true,
                        filterable: false,
                        placeholder: this.$t('common.select', {
                            item: '上传部门',
                        }),
                        options: this.orgs,
                        labelKey: 'name',
                        valueKey: 'id',
                    },
                    {
                        prop: 'key',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入手册名称、编号',
                    }
                ]
            };
        }
    },
    created() {
        this.getOrgList();
    },
    methods: {
        handleClick(key, id) {
            switch (key) {
                case 'down':
                    this.downHandle(id);
                    break;
                case 'del':
                    this.deleteHandle(id);
                    break;
                default:
            }
        },
        getOrgList() {
            this.$client.getDepartmentList().then(data => {
                this.orgs = data.list;
            });
        },
        showPdf(item) {
            previewPdf(
                this.baseServer + item.ossId + '?token=' + this.$store.state.token,
                item.name
            );
        },
        // 多选
        selectHandle(val) {
            this.selectedRecords = val;
        },
        preValidate() {
            if (this.selectedRecords == null || this.selectedRecords.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            if (this.selectedRecords.length > 1) {
                this.$message({
                    type: 'warning',
                    message: '只允许对一条进行操作'
                });
                return false;
            }
            return true;
        },
        // 新增 / 修改
        addOrUpdateHandle() {
            this.readDialog = {
                title: '上传手册',
                currentId: 0,
                visible: true,
                type: 'add',
            };
        },

        submitHandbookHandle() {
            this.$refs.addOrUpdate.submitHandle((rest) => {
                this.readDialog.visible = false;
                this.$refs.searchTable.searchHandler();
            });
        },
        // 下载
        downHandle(id) {
            const ids = [];
            ids.push(id);
            if (ids.length === 0) {
                this.$message.error('请选择手册！');
                return;
            }
            this.$confirm('确定进行下载操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                location.href = this.axios.defaults.baseURL + '/business/handbook/download?ids=' + ids.join(',') + '&token=' + this.$store.state.token;
            });
        },
        // 删除
        deleteHandle(id) {
            const ids = [];
            ids.push(id);
            if (ids.length === 0) {
                this.$message.error('请选择手册！');
                return;
            }
            this.$confirm('确定进行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delMaterialForTraining(ids).then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    });
                    this.$refs.searchTable.searchHandler();
                });
            });
        }
    }
};
</script>
